import React from 'react';
import './styles.scss';

const AboutUsPage: React.FC = () => {
    
    return (
        <div>

        </div>
    );
};

export default AboutUsPage;